import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
Vue.use(VueRouter);

const routes = [{
        path: '/login',
        name: 'login',
        component: () =>
            import( /* webpackChunkName: "layout" */ "../layouts/Loginhead.vue"),
        children: [{
                path: "/login",
                redirect: "/login/index"

            }, {
                path: "/login/index",
                name: "index",
                meta: {
                    keepAlive: true,
                    title:'后台|登录'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "index" */ "../views/login/Index"),
            },


        ]
    },
    {
        path: '/group',
        name: 'group',
        component: () =>
            import( /* webpackChunkName: "layout" */ "../layouts/Mainhead.vue"),
        children: [{
                path: "/group",
                redirect: "/group/index"

            }, {
                path: "/group/index",
                name: "index",
                meta: {
                    keepAlive: true,
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:true
                        }
                    ],
                    title:'后台|首页'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "index" */ "../views/group/Index"),
            },
            {
                path: "/group/creategroup",
                name: "creategroup",
                meta: {
                    keepAlive: true,
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/创建组照',
                            path:'/group/creategroup',
                            isactive:true
                        }
                    ],
                    title:'后台|创建组照'
                    
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "index" */ "../views/group/Creategroup"),
            },
            {
                path: "/group/editgroup",
                name: "editgroup",
                meta: {
                    keepAlive: true,
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/编辑组照',
                            path:'/group/editgroup',
                            isactive:true
                        }
                    ],
                    title:'后台|编辑组照'
                    
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "index" */ "../views/group/Editgroup"),
            },
            {
                path: "/group/sequelimages",
                name: "sequelimages",
                meta: {
                    keepAlive: true,  
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/续传图片',
                            path:'/group/sequelimages',
                            isactive:true
                        }
                    ],
                    title:'后台|续传图片'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "index" */ "../views/group/Sequelimages"),
            },
            {
                path: "/group/editimages",
                name: "editimages",
                meta: {
                    keepAlive: true,  
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/编辑图片',
                            path:'/group/editimages',
                            isactive:true
                        }
                    ],
                    title:'后台|编辑图片'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "index" */ "../views/group/Editimages"),
            },
        ]
    },
    {
        path: '/setting',
        name: 'setting',

        component: () =>
            import( /* webpackChunkName: "layout" */ "../layouts/Mainhead.vue"),
        children: [{
                path: "/setting",
                redirect: "/setting/index"
                
            },
            {
                path: "/setting/index",
                name: "index",
                meta: {
                    keepAlive: true,
                    tabname:'账号设置',
                    issetting:1,     
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/设置',
                            path:'/setting/index',
                            isactive:false
                        },
                        {
                            info:'/账号设置',
                            path:'/setting/index',
                            isactive:true
                        }
                    ],
                    title:'后台|设置|账号设置'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "viewedit" */ "../views/setting/Index"),
            },
            {
                path: "/setting/faceset",
                name: "faceset",
                meta: {
                    keepAlive: true,
                    tabname:'人脸库',
                    issetting:1,
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/设置',
                            path:'/setting/index',
                            isactive:false
                        },
                        {
                            info:'/人脸库',
                            path:'/setting/faceset',
                            isactive:true
                        }
                    ],
                    title:'后台|设置|人脸库'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/setting/Faceset"),
            },
            {
                path: "/setting/viewedit",
                name: "viewedit",
                meta: {
                    keepAlive: true,
                    issetting:1,
                    tabname:'人脸库',
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/设置',
                            path:'/setting/index',
                            isactive:false
                        },
                        {
                            info:'/人脸库',
                            path:'/setting/faceset',
                            isactive:false
                        },
                        {
                            info:'/查看和编辑',
                            path:'/setting/viewedit',
                            isactive:true
                        }
                    ],
                    title:'后台|设置|人脸库'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "viewedit" */ "../views/setting/Viewedit"),
            },
            {
                path: "/setting/usermanagement",
                name: "usermanagement",
                meta: {
                    keepAlive: true,
                    issetting:1,
                    tabname:'用户管理',
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/设置',
                            path:'/setting/index',
                            isactive:false
                        },
                                               {
                            info:'/用户管理',
                            path:'/setting/usermanagement',
                            isactive:true
                        }
                    ],
                    title:'后台|设置|用户管理'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "viewedit" */ "../views/setting/Usermanagement"),
            },
            {
                path: "/setting/recyclebin",
                name: "recyclebin",
                meta: {
                    keepAlive: true,
                    issetting:1,
                    tabname:'组照回收站',
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/设置',
                            path:'/setting/index',
                            isactive:false
                        },
                                               {
                            info:'/组照回收站',
                            path:'/setting/recyclebin',
                            isactive:true
                        }
                    ],
                    title:'后台|设置|组照回收站'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "viewedit" */ "../views/setting/Recyclebin"),
            },
            {
                path: "/setting/downloadrecords",
                name: "downloadrecords",
                meta: {
                    keepAlive: true,
                    issetting:1,
                    tabname:'下载记录',
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/设置',
                            path:'/setting/index',
                            isactive:false
                        },
                                               {
                            info:'/下载记录',
                            path:'/setting/downloadrecords',
                            isactive:true
                        }
                    ],
                    title:'后台|设置|下载记录'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "viewedit" */ "../views/setting/Downloadrecords"),
            }
            
            // {
            //     path: "/group/sequelimages",
            //     name: "sequelimages",
            //     meta: {
            //         keepAlive: true
            //     },
            //     // route level code-splitting
            //     // this generates a separate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: () =>
            //         import( /* webpackChunkName: "index" */ "../views/group/sequelimages"),
            // },



        ]
    },
    
    {
        path: '/searchresults',
        name: 'searchresults',
        component: () =>
            import( /* webpackChunkName: "layout" */ "../layouts/Mainhead.vue"),
        children: [{
                path: "/searchresults",
                redirect: "/searchresults/index"

            }, {
                path: "/searchresults/index",
                name: "index",
                meta: {
                    keepAlive: true,
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/检索结果',
                            path:'/searchresults/index',
                            isactive:true
                        },                              
                    ],
                    title:'后台|检索结果'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "index" */ "../views/Searchresults/Index"),
            },


        ]
    },    
    {
        path: '*',
        name: '404',
        component: () =>
            import(
                "../views/404.vue")
    },  
    {
        path: '/downloadpackage',
        name: 'downloadpackage',
        meta:{
            title:'深篮体育'
        },
        component: () =>
            import(
                "../views/login/Downloadpackage")
    },  
    {
        path: '/userdelete/xerfgsd',
        name: 'userdelete',
        meta:{
            title:'深篮体育'
        },
        component: () =>
            import(
                "../views/login/Userdelete")
    },  
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
      }
    
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title   
    console.log(to)
    if (to.path == '/') {
        next({
            path: '/login/index'
        })

    }
    let sysuser = localStorage.getItem('sysuser');
    if (sysuser == null && to.path !== '/login/index' && to.path !== '/album/index' && to.path !== '/downloadpackage' && to.path !== '/userdelete/xerfgsd' ) {
        if(to.path=='/album/atlas'){
            next({
                path: '/album/index'
            })
        }else{
        next({
            path: '/login/index'
        })
    }
    }
    if (to.path !== from.path) {
        NProgress.start();
    }
    console.log(from.path)
    NProgress.done();
    next();
});
router.afterEach(() => {
    NProgress.done();
})

export default router;